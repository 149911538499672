import React, { Fragment, useEffect, useState, useContext, useParams } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Head } from './Head';
import { PlayerMain } from './PlayerMain';
import { Context } from '../context';

import { Tracks } from './Tracks';

import PlayerScrubbr from './PlayerScrubbr';
import { PlayerTop } from './PlayerTop';
import { Preloader } from './Preloader';
import { Start } from './Start';
import { PlayerChapters } from './PlayerChapters';
import { PlayerVideo } from './PlayerVideo';
import { Subtitles } from './Subtitles';
import { PlayerVideoSimple } from './PlayerVideoSimple';
import { Footer } from './Footer';
import { TrackSubtitles } from './TrackSubtitles';

export function LayoutSubtitles() {
  const context = useContext(Context);

  const { state, handles } = context;

  useEffect(() => {

    handles.setRussian(true);
    handles.setSubtitleMode(true);

  }, []);

  // const { mediaId } = useParams();
  const location = useLocation();




  return (
    <>

      {/* <Start /> */}

      <Head />

      {!context.state['mp3'] && <Preloader />}

      {/* <PlayerTop>
          <PlayerScrubbr>
          <PlayerChapters />
          </PlayerScrubbr>
        </PlayerTop> */}

      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'simple' &&
        <PlayerVideoSimple context={context}>
          <Subtitles />
        </PlayerVideoSimple>}


      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'advanced' &&
        <PlayerVideo context={context}>
          <Subtitles />
        </PlayerVideo>}



      {Boolean(context.state['mp3']) && <>
        <TrackSubtitles
          subtitleMode={true}
          subtitles={true}
        >
          <PlayerMain subtitleMode={context.state.subtitleMode} />
        </TrackSubtitles>
      </>}


      <Footer context={context} />

    </>
  )
}
