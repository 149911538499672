import React, { useState, useContext, useRef, useEffect } from 'react';
import { Context } from '../context';
import { PlayerTranslated } from './PlayerTranslated';
import { Flex, Ico, Overlay } from './Uikit';

export const ReelsDragging = React.memo(({
  order = 0,
  topic,
  context,
  id,
  ind,
  oneTrack,
  marginLeft,
  marginRight,
  handlePlayNext = () => void (0),
  // clickHandler,
  start,
  length,
  // trimmed,
  // trimmed_start,
  // end_rus_milli,
  // start_rus_milli,
  totalLength,
  tracks = [],
  nextTrack,
  prevTrack,
  onUpdatePosition = () => void (0),
  onUpdateLength = () => void (0),
  // timeoutRef,
  // openEditor,
  draggingTrack,
  subtitles = '',
  // setNewData,
  children,
  optionsShown
}) => {

  const zoomLevel = 100 - context.state['zoomLevel'];
  const [position, setPosition] = useState(start);
  const [lengthPosition, setLengthPosition] = useState(length);
  const [isDragging, setIsDragging] = useState(false);
  const [isLengthing, setIsLengthing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [lengthX, setLengthX] = useState(0);

  const [dragStart, setDragStart] = useState(false);

  // useEffect(() => {
  //   context.handles.setIsDragging(isDragging)
  // }, [isDragging])


  useEffect(() => {
    if (length !== lengthPosition) {
      setLengthPosition(length)
    }
  }, [length])

  const clickHandler = () => {
    // const scrubberWidth = context.state.zoomFragment[1] - context.state.zoomFragment[0];
    context.handles.setPlayingFragment(oneTrack['id']);
    // const newStart = oneTrack['start'];
    const newStart = position;
    // console.log('newStart = ', newStart);
    context.handles.setPlayStart(newStart);
    // context.handles.setPlayOriginalStart(oneTrack['original_start']);
    context.handles.setPlaying(true);
    // context.handles['setZoomFragment']([startX / zoomLevel, (startX + context.state.scrubberWidth) / zoomLevel]);
    // console.log('oneTrack[start] = ', startX);

    //TODO: setPlaybackTime
    // context.handles.setPlaybackTime(oneTrack['start'])

    // console.log(`Playing Fragment ID set to: ${oneTrack['id']}`);
    context.handles['setPlayingFragmentNext']();
    // if (context.state.playingFragment === oneTrack['id'] && !context.state['playing']) {
    // context.handles.setPlaying(true)
    // }
    // if (context.state.playingFragment !== oneTrack['id'] && !context.state['playing']) {
    //   context.handles.setPlaying(false)
    // }
  }




  const getTrackClass = (track, tracks) => {
    const { playingFragment, playingFragmentNext, changed } = context.state;
    const trackId = track.id;

    let className = "x_track_fragment x_track_fragment--reel";

    // className += ` x_track_fragment--${track.lane}`

    // if (nextTrack && nextTrack.start < track.start + track.duration) {
    //   className += ' x_track_fragment--2'
    // }
    if (changed.includes(trackId)) className += " changed";
    if (trackId === playingFragment) className += " active";



    // if (trimmed) className += " trimmed";
    // if (context.state.changedRecently.includes(id)) className += " changed_recently";

    // Determine if this track is the next one to play and is currently in the delay period
    // const isNextTrackInDelay = trackId === playingFragmentNext && timeoutRef.current;
    // const isNextTrackInDelay = 0;

    // if (isNextTrackInDelay || trackId === context.handles.getNextTrackId(playingFragment, tracks)) {
    //   className += " next";
    // }

    return className;
  };



  const dragHandleRef = useRef(null);
  const dragHandleRefStart = useRef(null);
  const lengthHandleRef = useRef(null);

  const handleClick = () => {
    if (!isDragging) {
      // clickHandler(oneTrack)
    }
  }

  const handlePositionMouseDown = (e) => {
    if (e.type === 'touchstart') {
      e.preventDefault();
    }
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setIsDragging(true);
      setStartX(e.clientX);
    }

    if (dragHandleRefStart.current && dragHandleRefStart.current.contains(e.target)) {
      setIsDragging(true);
      setDragStart(true)
      setStartX(e.clientX);
    }

    if (
      (dragHandleRef.current && !dragHandleRef.current.contains(e.target)) &&
      (lengthHandleRef.current ? !lengthHandleRef.current.contains(e.target) : true)) {
      // clickHandler();
    }
  };

  const handleLengthMouseDown = (e) => {
    if (e.type === 'touchstart') {
      e.preventDefault();
    }
    if (lengthHandleRef.current && lengthHandleRef.current.contains(e.target)) {
      setIsLengthing(true);
      setLengthX(e.clientX);
    }
  }

  const getMaxSpace = () => {
    const currentIndex = tracks.findIndex(t => t.id === id);
    const isLastTrack = currentIndex === tracks.length - 1;

    let min = 0;
    let max = context.state.durationFull;


    // min = currentIndex > 0
    //   ? tracks[currentIndex - 1]['start'] + tracks[currentIndex - 1]['length']
    //   : 0;
    // // max = context.state['durationFull'] - length;
    // max = isLastTrack
    //   ? context.state['durationFull']
    //   : tracks[currentIndex + 1]['start'];


    // // Correction for the max calculation for the last track
    // // if (isLastTrack) {
    // //   max = context.state['durationFull'] - length;
    // // }
    // console.log('min = ', min);
    // console.log('max = ', max);

    // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
    return { min, max };
  };

  const getMaxLength = () => {
    const currentIndex = tracks.findIndex(t => t.id === id);
    const isLastTrack = currentIndex === tracks.length - 1;

    let min = 1000;
    // let what = subtitles ? 'start_rus_milli' : 'start'
    let what = 'start'
    let max = isLastTrack ? context.state['durationFull'] : tracks[currentIndex + 1][what];

    // Correction for the max calculation for the last track
    if (isLastTrack) {
      max = context.state['durationFull'];
    }

    // console.log(`Track ID: ${id}, Min: ${min}, Max: ${max}, Is Last Track: ${isLastTrack}`);
    return { min, max };
  };



  const handleMouseMove = (e) => {
    if (isDragging) {
      const { min, max } = getMaxSpace();
      // console.log('getMaxSpace = ', getMaxSpace());
      const deltaX = e.clientX - startX;
      const newPosition = position + deltaX * zoomLevel;

      if (newPosition >= min && newPosition <= max) {
        // console.log(`New Position for Track ID ${id}: ${newPosition}`);
        setPosition(newPosition);
        // console.log('newPosition = ', newPosition);

        if (dragStart) {
          const newLengthPosition = lengthPosition - (deltaX * zoomLevel);
          if (newLengthPosition >= min && newLengthPosition <= max) {
            // console.log('newLengthPosition = ', newLengthPosition);
            setLengthPosition(newLengthPosition)
          }
        }

        setStartX(e.clientX);
        context.handles.addNewChange(id, 'changedReels');
      }
    }
    if (isLengthing) {
      const { min, max } = getMaxLength();
      const deltaX = e.clientX - lengthX;
      const newLengthPosition = lengthPosition + deltaX * zoomLevel;
      // console.log('newLengthPosition = ', newLengthPosition);
      // The new length of the fragment

      // if (newLengthPosition >= min && newLengthPosition + position <= max) {
      setLengthPosition(newLengthPosition);
      setLengthX(e.clientX);
      context.handles.addNewChange(id, 'changedReels');
      // }
    }
  };


  useEffect(() => {
    if (!isDragging && startX && startX !== start) {
      // console.log('position = ', position);
      // onUpdatePosition(id, position, dragStart);
    }
  }, [isDragging])

  // useEffect(() => {
  //   // if (subtitles &&
  //   //   !isLengthing && lengthX && lengthX !== context.state['translationData'][ind]['length']) {
  //   //   onUpdateLength(id, lengthPosition, subtitles, dragStart);
  //   // }
  //   // if (!subtitles &&
  //   !isLengthing && lengthX && lengthX !== context.state['translationData'][ind]['end_rus_milli'] - context.state['translationData'][ind]['start_rus_milli']) {
  //   onUpdateLength(id, lengthPosition, subtitles, dragStart);
  //   // }
  // }, [isLengthing])



  const handleMouseUp = () => {
    setIsDragging(false);
    setIsLengthing(false);
    setDragStart(false);
    //TODO: play on drag
    // context.handles['setPlayingFragment'](id);
    // if (timeoutRef.current) {
    //   clearTimeout(timeoutRef.current);
    // }
  };

  useEffect(() => {
    const element = draggingTrack.current;

    const handleTouchMove = (e) => {
      // Handle touch move
      handleMouseMove(e.touches[0]); // Use the first touch point for the mouse equivalent
    };

    const handleTouchEnd = (e) => {
      // Handle touch end
      handleMouseUp(e.changedTouches[0]); // Use the first touch point for the mouse equivalent
    };

    if (element) {
      element.addEventListener('mousemove', handleMouseMove);
      element.addEventListener('mouseup', handleMouseUp);

      // Add touch event listeners
      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousemove', handleMouseMove);
        element.removeEventListener('mouseup', handleMouseUp);

        // Remove touch event listeners
        element.removeEventListener('touchmove', handleTouchMove);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging, isLengthing, position, lengthPosition]);

  useEffect(() => {
    // console.log('oneTrack[captions] = ', oneTrack['captions']);
  }, [oneTrack])



  return (<>

    <div className="x_track_hover x_track_hover--reel"
      style={{
        top: `${order * 4.2}em`,
      }}
    />



    <div

      style={{
        zIndex: optionsShown === id ? 1000 : 0,
        position: 'absolute',
        left: `${position / zoomLevel}px`,
        top: `${order * 4.2}em`,
        width: `${lengthPosition / zoomLevel}px`,
        cursor: 'pointer',
        // boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : 'none'}`,
        // boxShadow: `${!subtitles && trimmed ? `${trimmed / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : `${trimmed_start ? `inset ${trimmed_start / zoomLevel}px 0 0 0px rgba(255, 0, 0, 0.7)` : ''}`}`,
        // zIndex: `${trimmed ? 1 : 'inherit'}`,
        // background: trimmed_start ? 'red' : 'inherit',
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // height: subtitles ? '1em' : '4em'
        // height: '4em'
      }}
      className={getTrackClass(oneTrack, tracks)}
    // key={`${context.state['mediaId']}_${oneTrack['id']}`}
    // draggable="true"
    // onClick={handleClick}
    >



      {children}


      {/* <div className="x_track_start" /> */}

      <div onMouseDown={handlePositionMouseDown}>

        {!isDragging && ((!Boolean(oneTrack['file'])) || Boolean(oneTrack['add_background'])) && <div className={isLengthing ? "x_track_length0 dragging" : "x_track_length0"}>
          <div className="x_track_length" ref={lengthHandleRef}
            onMouseDown={handleLengthMouseDown}
          >
            {!isLengthing && <div className="ico">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>}

        {(!Boolean(oneTrack['file'])) && <div className={isLengthing ? "x_track_length0 x_track_length0--start dragging" : "x_track_length0 x_track_length0--start"}>
          <div className="x_track_length x_track_length--start"
            ref={dragHandleRefStart}
          >
            {!isLengthing && <div className="ico">drag_indicator</div>}
            {isLengthing && <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>}
          </div>
        </div>}


        {/* {(!Boolean(oneTrack['file'])) && <>
          <Overlay style={{ color: 'white', fontSize: '0.6em' }}>
            <Flex gap='.2em'>
              <Ico>music_off</Ico>Нет файла
            </Flex>
          </Overlay>
        </>} */}

        <div style={{
          overflow: 'hidden',
          width: lengthPosition / zoomLevel
        }} >



        </div>

        {!isLengthing && <div className="x_track_drag0" ref={dragHandleRef} style={{ top: '-1em', bottom: 'auto' }}>
          <div className="x_track_drag-handle">
            <svg
              className='x_track_drag-icon'
              width={20} height={14} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 1.2071V12.7929C7 13.2383 6.46143 13.4614 6.14645 13.1464L0.707108 7.7071C0.316584 7.31658 0.316585 6.68341 0.707108 6.29289L6.14645 0.853549C6.46143 0.538567 7 0.761651 7 1.2071ZM13 1.2071V12.7929C13 13.2383 13.5386 13.4614 13.8536 13.1464L19.2929 7.7071C19.6834 7.31658 19.6834 6.68341 19.2929 6.29289L13.8536 0.853549C13.5386 0.538567 13 0.761651 13 1.2071Z" fill="white" />
            </svg>
          </div>
          <div className="x_track_fragment_drag"
          // style={{
          //   // left: 0,
          //   // right: 0,
          //   left: Math.floor(marginLeft / zoomLevel * -1),
          //   right: Math.floor(marginRight / zoomLevel * -1),
          // }}
          />
        </div>}
      </div>


    </div>

  </>
  );
})

