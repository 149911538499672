import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { Context } from '../context';
import { Head } from './Head';
import '../trans.css';
import { Input, List, message, Row, Col, Segmented } from 'antd';
import { TranslateLine } from './TranslateLine';
import { PlayerMain } from './PlayerMain';
import { PlayerTranslate } from './PlayerTranslate';

const { TextArea } = Input;

export function LayoutTranslate(props) {
  const context = useContext(Context);
  const { children } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();


  const [tracks, setTracks] = useState([]);
  const [editing, setEditing] = useState();


  useEffect(() => {
    if (!tracks.length && context.state['translationData'].length
      || (context.state['translationData'].length && context.state['translationData'][0].folder !== context.state.folder)
    ) {
      setTracks(context.state['translationData'])
    }
  }, [context.state.translationData, context.state.folder])

  return (
    <>

      <Head
        playSpacebar={false}
        videoToggleShown={false}
        saveShown='changedTranslation'
      />

      <div className="xt_preview">
        <PlayerTranslate />
      </div>
      <div className="xt_layout">
        <div className="xt_translate">


          {tracks.map((oneTrack, ind, array) => {

            return (<TranslateLine
              key={`track_${oneTrack['id']}_${ind}`}
              track={oneTrack}
              editing={editing}
              setEditing={setEditing}
            />)

          })}

        </div>
      </div>

    </>
  )
}