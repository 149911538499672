import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Head } from './Head';
import { PlayerMain } from './PlayerMain';
import { Context } from '../context';

import { Tracks } from './Tracks';

import PlayerScrubbr from './PlayerScrubbr';
import { PlayerTop } from './PlayerTop';
import { Preloader } from './Preloader';
import { Start } from './Start';
import { PlayerChapters } from './PlayerChapters';
import { PlayerVideo } from './PlayerVideo';
import { Subtitles } from './Subtitles';
import { PlayerVideoSimple } from './PlayerVideoSimple';
import { Footer } from './Footer';

export function LayoutComponent({ children }) {
  const context = useContext(Context);


  // const { mediaId } = useParams();
  const location = useLocation();


  const { mediaId } = useParams();


  useEffect(() => {
    // const mediaId = location.pathname.split('/')[1];
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');
    const lang = queryParams.get('lang');
    const video = queryParams.get('video');
    const subtitles = queryParams.get('subtitles');
    // Extract the folder from queryParams each time the effect runs
    const currentFolder = queryParams.get('folder');

    // Check and update context only if the folder has changed
    if (mediaId && context.state['mediaId'] !== mediaId) {
      context.handles.setMediaId(mediaId);
      context.handles.setName(name);
      context.handles.setLang(lang);
      context.handles.setFolder(currentFolder);
    }
    context.handles.setVideo(video ? 'sm' : '');
    // context.handles.setSubtitleMode(subtitles ? true : false);

    if (currentFolder && context.state['folder'] !== currentFolder) {
      context.handles.setFolder(currentFolder);
    }
  }, [location.search]); // Depend on location.search to re-run the effect when query params change


  return (
    <>

      {children}

    </>
  )
}

export const Layout = React.memo(LayoutComponent);