import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { PreloaderGif } from './Preloader';

export function PlayButton({ enter = false, playSpacebar = true, defaultAction = true, context = {} }) {


  useEffect(() => {
    if (enter) {

      const handleKeyPress = (e) => {
        if (e.key === " " && !context.state.pop && playSpacebar) { // Spacebar key
          e.preventDefault()
          context.handles.setPlaying(!context.state.playing);
          context.handles.setPlayingFragment()
        }
      }
      document.addEventListener('keydown', handleKeyPress);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      }
    }
  }, [enter, context]);

  return (
    <>
      <div className="x_player_control_play" onClick={() => {
        if (defaultAction && context.state['mp3']) {
          if (context.state['playing']) {
            context.handles.setPlayingFragment()
          }
          context.handles.setPlaying(!context.state['playing'])
        }
      }
      }>
        {context.state['mp3'] && <div className="ico ico--40">
          {!context['state']['playing'] && <div>play_arrow</div>}
          {context['state']['playing'] && <div>pause</div>}
        </div>}
        {!context.state['mp3'] && <PreloaderGif />}
      </div>
    </>
  )
}
