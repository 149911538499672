import React, { Fragment, useEffect, useState, useContext } from 'react'
import logo from './logo.svg';
import { Provider } from './context.js';
import { RoutesMain } from './components/RoutesMain';
import './components/main.css';
import './components/fonts.css';
import './components/add.css';
import { ReelsProvider } from './contextReels.js';


function App() {
  return (
    <div className="App b0">
      <Provider>
        <ReelsProvider>
          <RoutesMain />
        </ReelsProvider>
      </Provider>
    </div>
  );
}

export default App;
