import React, { useRef, useState, useCallback, useContext, useEffect } from 'react';
import ReactWaves from '@dschoon/react-waves';
import { Context } from '../context';
import { PlayButton } from './PlayButton';

export function PopPlayer({
  spaceBar,
  width,
  id,
  handleClick = () => void (0),
  handlePlayNext = () => void (0),
  children,
  mp3,
  options = {
    barHeight: 2,
    barRadius: 3,
    cursorWidth: 0,
    height: 60,
    hideScrollbar: true,
    progressColor: '#ff7606',
    responsive: true,
    waveColor: '#D1D6DA',
    interact: false,
    dragSelection: false
  }
}) {
  const context = useContext(Context);
  const wavesRef = useRef(null);
  const waveformRef = useRef(null); // Reference to the waveform container
  const zoomLevel = 100 - context.state['zoomLevel'];

  const [wavesurfer, setWavesurfer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);

  const subtitleMode = context.state.subtitleMode;

  const onLoading = useCallback(({ wavesurfer, originalArgs = [] }) => {
    if (originalArgs[0] === 100) {
      setWavesurfer(wavesurfer);
    }
  }, []);

  const onPosChange = useCallback((newPos, wavesurfer) => {
    if (newPos !== pos) {
      setPos(newPos);
      setWavesurfer(wavesurfer);
    }
  }, [pos]);

  const handleWaveClick = (event) => {
    setPlaying(true)
    if (!wavesurfer) return;
    const boundingRect = waveformRef.current.getBoundingClientRect();
    const x = event.clientX - boundingRect.left;
    const relativeX = x / boundingRect.width;
    const duration = wavesurfer.getDuration();
    const newTime = relativeX * duration;

    wavesurfer.seekTo(relativeX);
    setPos(newTime);
  };

  useEffect(() => {
    return () => {
      if (wavesurfer && !context.state['playingFragment'] !== id) {
        wavesurfer.seekTo(0);
      }
    };
  }, [context.state['playingFragment'], id, wavesurfer]);




  useEffect(() => {


    const handleKeyPress = (e) => {
      if (e.key === " " && spaceBar && context.state.pop) { // Spacebar key
        e.preventDefault()
        setPlaying(prev => !prev);
      }
    }
    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    }

  }, [context, spaceBar]);







  return (
    <div
      ref={waveformRef}
      style={{
        position: 'relative',
        paddingTop: '1em',
        width: width
      }}
      onClick={handleWaveClick} // Add click handler here
    >
      <ReactWaves
        ref={wavesRef}
        audioFile={mp3}
        className={'react-waves'}
        options={{ ...options }}
        volume={context.state['volumeTranslated'] / 100}
        zoom={100 - zoomLevel * ((90 - zoomLevel) / 10)}
        pos={pos}
        playing={playing}
        onPosChange={onPosChange}
        onLoading={onLoading}
        // onFinish={handlePlayNext}
      />
      <div style={{
        position: 'absolute',
        top: '-2.4em', left: 0, right: 0, bottom: 'auto',
        height: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}>
        <PopPlayerPlay playing={playing} handleClick={setPlaying} defaultAction={false} />
      </div>
    </div>
  );
}

export function PopPlayerPlay({ playing, handleClick }) {
  return (
    <>
      <div className="x_player_control_play" style={{ width: '2em', height: '2em' }} onClick={(e) => {
        e.stopPropagation()
        handleClick(!playing);
      }}>
        <div className="ico ico--20">
          {!playing && <div>play_arrow</div>}
          {playing && <div>pause</div>}
        </div>
      </div>
    </>
  );
}
