import React, { useEffect, useRef, useState, useContext } from 'react';
import { Context } from '../context';
import { PreloaderGif, PreloaderPercent, PreloaderTracks } from './Preloader';

const API_URL = process.env.REACT_APP_API_URL;

export function PlayerVideo({ context = {}, children }) {
  // const context = useContext(Context);
  const videoRef = useRef(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);

  const videoUrl = context.state.videoUrl ? `${API_URL}/download_rus?file_name=${context.state.mediaId}/${context.state.videoUrl}` : '';

  useEffect(() => {
    console.log('context.state.video = ', Boolean(context.state.video));

    if (context.state.video) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', videoUrl, true);
      xhr.responseType = 'blob';

      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setLoadingProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blobUrl = URL.createObjectURL(xhr.response);
          setVideoBlobUrl(blobUrl);
        }
      };

      xhr.onerror = () => console.error('Error fetching video');
      xhr.send();
    }
  }, [context.state.videoUrl, context.state.video]);

  // Play or pause the video based on the 'playing' prop
  useEffect(() => {
    if (videoRef.current) {
      if (context.state.playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [context.state.playing]);

  // Set the start time of the video based on the 'startTime' prop
  useEffect(() => {
    let startTime = context.state.playOriginalStart;
    if (startTime != null && videoRef.current) {
      videoRef.current.currentTime = startTime / 1000;
    }
  }, [context.state.playOriginalStart]);

  return (
    <>
      <div className="c">
        <div className="x_player_video0">



          {children}



          {Boolean(context.state.videoUrl)
            && Boolean(context.state['mp3'])
            && Boolean(context.state.video)
            && Boolean(loadingProgress === 100)
            && <div className="x_player_video_sett0">

            <div className={`x_player_video_set_1 ${context.state.video === 'sm' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('sm')}
            >
              <div className="ico">
                <div>crop_7_5</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === 'md' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('md')}
            >
              <div className="ico">
                <div>crop_5_4</div>
              </div>
            </div>

            <div className={`x_player_video_set_1 ${context.state.video === 'full' ? 'active' : ''}`}
              onClick={() => context.handles.setVideo('full')}
            >
              <div className="ico">
                <div>fullscreen</div>
              </div>
            </div>

          </div>}


          {Boolean(videoUrl) && loadingProgress < 100 && <PreloaderTracks>
            {/* <PreloaderGif width={44} height={44} /> */}
            {Boolean(context.state.video) && <PreloaderPercent>Видео загружается: {Math.round(loadingProgress)}%</PreloaderPercent>}
          </PreloaderTracks>}

          {Boolean(context.state.video) && Boolean(videoUrl) && loadingProgress === 100 && <video
            preload="auto"
            onLoadedData={() => context.handles.setVideo('sm')}
            style={{ zIndex: 0, visibility: Boolean(context.state.video) ? 'visible' : 'hidden' }}
            ref={videoRef}
            className={`x_player_video x_player_video--${context.state.video}`}
            muted
          >
            <source src={videoBlobUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>}


          {!Boolean(loadingProgress === 100) && <div className={`x_player_video`}></div>}
        </div>
      </div>
    </>
  );
}
